<script setup>
</script>
<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<style scoped>

</style>
